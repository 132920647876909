@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Loading Animation CSS */

.svg-calLoader {
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.cal-loader__plane {
  fill: rgb(220 38 38);
}
.cal-loader__path {
  stroke: rgb(39 39 42);
  animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}

/* Typing Dots Animation */

.jumping-dots span {
  position: relative;
  bottom: 0px;
  -webkit-animation: jump 1500ms infinite;
  animation: jump 2s infinite;
}
.jumping-dots .dot-1 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.jumping-dots .dot-2 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.jumping-dots .dot-3 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

@-webkit-keyframes jump {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

@keyframes jump {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

/* Scrollbar Settings */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(24 24 27);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(47, 47, 47);
}
